import { gsap, ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

export default class HorizontalScroll {
  constructor(element) {
    this.element = element;
    this.sections = element.querySelectorAll('.section');

    window.addEventListener('resize', this.update);

    this.initialize();
  }

  initialize() {
    this.update();
  }

  update() {
    const _this = this;
    gsap.to(_this.sections, {
      xPercent: -100 * (_this.sections.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: _this.element,
        pin: true,
        scrub: 1,
        // snap: 1 / (this.sections.length - 1),
        end: () => '+=' + _this.element.offsetWidth,
      },
    });
  }

  destroy() {}
}
